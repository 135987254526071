
.dashboard{
    background-color: white;
    height: 100%;
}
.margin_right_0 {
    margin-right: 0px;;
}

.padding_left_0{
    padding-left: 0px;;
}
.padding_right_0{
    padding-right: 0px;;
}
.dashboard-header .dropdown {
    
    font-family: barlow-medium !important;
}

@media screen and (max-width: 768px) {
    .margin_right_0 {
        /* display: none; */
    }
    .in-mobile-h3{
        display: inline;
        color: black;
    }
  }


/* @media screen and (min-width: 768px) {

    .in-mobile-h3{
        display: none;
    }
  } */

  @media screen and (max-width: 768px) {
    .logo-icon {
        display: none;
    }
  }