.dashboard-middile h3{
    font-size: 1.25rem;
    color: #C2C8CA;
    float: left;
    padding-top: 28px;
    font-family: barlow-medium;
    padding-left: 32px;
    padding-bottom: 15px;
    letter-spacing: .025rem;
}
.dashboard-header{
    height: 80px;
}
.dashboard-header h3 {
    font-size: 1.25rem;
    color: #C2C8CA;
    float: left;
    padding-top: 28px;
    font-family: barlow-medium;
    padding-left: 22px;
    letter-spacing: .025rem;
}
.dashboard-map{
    /* background: url('../../Images/map.jpg');
    background-position: center; */
    background-position: center;
    height: 300px;
    width: 100%;
    z-index: 99;
}

.table_view_position{
    position: relative;
    left: 30px;
    height: 200px;
    display: block;
    overflow-y: scroll;
}
.DateRangePicker__Month{
}
.padding-top-25{
    padding-top: 25px;
}
.DateRangePicker__CalendarSelection{
    background-color: #6AC7F4 !important;
}
.table-bordered td, .table-bordered th ,.table-bordered {
    border: 0px solid ;
    font-family: barlow-medium;
}
.table-bordered th 
{
    background-color: #F2F3F0;
}
.table-striped tbody {
    cursor: pointer;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: white;
}
.DateRangePicker {
    font-family: barlow-medium;
}
.modal_header{
    margin: auto;
    padding: 15px;
    padding-bottom: 25px;
    color:teal;
}
.modal_item{
padding-left: 35px;
padding-bottom: 0px;
}
.modal_close{
    width: 100px;
    margin: auto;
    margin-bottom: 25px;
}
.username{
    float: right;
    position: relative;
    top:27px;
    right:15px;
    font-size: 1.125rem;
    color: black;
}

.dropdown button{
    background-color: white !important;
    border: 0px;
}
.dropdown button:hover{
    background-color: white !important;
    border: 0px;
    color: cornflowerblue;
}
.dashboard-header i {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 10px;
    position: relative;
    top: -3px;
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
 
  .table_view_position th {
      padding-left: 1.925rem;
      padding-right: 1.925rem;
  }
  .fonttable {
      padding-top: 4px !important ;
      padding-bottom: 0px !important;
      font-size: .9375rem;
  }
  .userpic{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .leaflet-container {
    height: 300px;
    width: 100%;
}
.table-zindex{
    z-index: 2;
}
.padding_left_20{
    padding-left: 20px;
}
.padding_left_40{
    padding-left: 40px;
}
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.table_view_position::-webkit-scrollbar {
    background-color:#fff;
    width:16px;
    position: relative;
    left: 50px;
}

/* background of the scrollbar except button or resizer */
.table_view_position::-webkit-scrollbar-track {
    background-color:#fff
}
.table_view_position::-webkit-scrollbar-track:hover {
    background-color:#f4f4f4
}

/* scrollbar itself */
.table_view_position::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:5px solid #fff
}
.table_view_position::-webkit-scrollbar-thumb:hover {
    background-color:#a0a0a5;
    border:4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.table_view_position::-webkit-scrollbar-button {display:none}

.middile_second h3{
    padding-left: 62px;
}
